<template >
  <div>
    <Loading v-if="loading"></Loading>
    <div v-if="!loading">
      <!-- ======= Breadcrumbs ======= -->
          <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li><a >About First Consult</a></li>
          
        </ol>
        <h2>About First Consult</h2>

      </div>
    </section>
     
      <!-- End Breadcrumbs -->

      <!-- ======= Services Section ======= -->
      <section id="values" class="values" style="">
        <div class="container center-element" data-aos="fade-up">
             
            
            
          <p class="text-left col-md-12" style="    font-size: 18px;
          color: #000000;
          text-align: justify !important;
          padding: 0;
          border-bottom: solid 2px #012970;">
            First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.
          <br>
          <br>
          </p>
          <hr style="color:white">
          </div>
          </section>
     <section id="values" class="values">
      <div class="container" data-aos="fade-up">
      

        <div class="row" style="">
          <div data-aos="fade-up"  class="col-lg-4 center-element aos-init aos-animate" style="margin-bottom: 20px;"><div class="row"><h2 class="sub-header">Areas of Expertise</h2><br><span style="
    font-size: 18px;
    color: #565555;
"> Our experience in Ethiopian development since 2006 spans a range of sectors and partnerships. Broadly organized, our expertise are in ... <br></span></div></div>
          <div
            v-for="(areaOf,index) in areaOfExperties" :key="index"
            class="col-lg-4"
            data-aos="fade-up"
            style="margin-bottom: 10px"
            
          >
            <div class="box" @click="CurrentAreaOf = areaOf" v-b-modal.modal-xl>
              <div style="width:100%" class="horizontal-center">
                <img
                :src="areaOf.icon"
                class="img-fluid"
                alt=""
                width="50%"
              />
              </div>
              
              <h3 class="text-center">{{areaOf.header}}</h3>
       <span class="colapsed-text text-center" v-html="areaOf.detail"></span>
      

            </div>
          </div>


        </div>
      </div>
      
      
        
        <b-modal
          id="modal-xl"
          size="xl"
          title="Extra Large Modal"
          ok-only
          ok-title="Close"
          hide-header>
          <div v-if="CurrentAreaOf" style="padding:50px">
        
          <div style="width:100%" class="horizontal-center">
                <img
                :src="CurrentAreaOf.icon"
                class="img-fluid"
                alt=""
                width="30%"
              />
              </div>
            <h3 class="sub-header">{{CurrentAreaOf.header}}</h3>
       <span  v-html="CurrentAreaOf.detail"></span>  </div>
          </b-modal>
        
      <br>
      <br>
    </section>
      <!-- End Values Section -->

      <!-- ======= Team Section ======= -->
      <section id="values" class="values" style="overflow:initial;">
        <div class="container" data-aos="fade-up" delay="200">
          <header class="section-header ">
            <p>Our Leadership</p>
          </header>

 <div class="row gy-4">
            <div
              v-for="(person, index) in People"
              :key="index"
              v-bind:class="{'col-lg-9 col-md-6 d-flex align-items-stretch card-height' : activePeople == person.id,  'col-lg-3 col-md-6 d-flex align-items-stretch card-height':activePeople != person.id,}"
              @click="changeActive(person.id)"
   
            >
              <div 
              v-bind:class="{'row vertical-center' : activePeople == person.id,  '':activePeople != person.id,}"
              
              class="box team-card cart-to-hover " style="width:100%;padding:0">
                <div 
                v-bind:class="{'col-md-4' : activePeople == person.id,  '':activePeople != person.id,}"
                class="member-img ov-image">
                  <img :src="person.image" id="images-in" class="img-fluid" alt="" width="100%"  style="object-fit: cover;
    width: 100%;
    height: 250px;"/>
                </div>
                  <div style="padding:20px" v-bind:class="{'col-md-8' : activePeople == person.id,  '':activePeople != person.id,}">
              <h4 style="text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    color: #013289;
  
    padding: 0;
    margin: 0;">{{ person.fullName }}</h4>
                  <span v-if="person.header" >{{
                    person.header
                  }}</span>
                  <span style="font-size:14px" v-bind:class="{'colapsed-text-onclick' : activePeople == person.id,  'colapsed-text ':activePeople != person.id,}"  v-html="person.details"></span>
      </div>
                <!-- <div 
                v-bind:class="{'col-md-8' : activePeople == person.id,  '':activePeople != person.id,}"
                class="member-info">
                  <h4>{{ person.fullName }}</h4>
                  <span v-if="person.header" >{{
                    person.header
                  }}</span>
                  <span v-bind:class="{'colapsed-text-onclick' : activePeople == person.id,  'colapsed-text ':activePeople != person.id,}"  v-html="person.details"></span>
                </div> -->
              </div>
            </div>
          </div>
   



<!-- 
          <div class="row gy-4">
            <div
              v-for="(person, index) in People"
              :key="index"
              class="col-lg-3 col-md-6 d-flex align-items-stretch card-height"
           
              
   
            >
            <div class="box team-card cart-to-hover" style="width:100%;padding:0"  @click="singleperson = person"
              v-b-modal.modal-person>
              <div 
               
                class="member-img ov-image">
                
                  
                  <img :src="person.image" id="images-in" class="img-fluid" alt="" width="100%"  style="object-fit: cover;
    width: 100%;
    height: 380px;"/>
                </div>
              <div style="padding:30px">
              <h4 style="text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: #013289;
  
    padding: 0;
    margin: 0;">{{ person.fullName }}</h4>
                  <span v-if="person.header" >{{
                    person.header
                  }}</span>
      </div>

            </div>
              
               
            </div>
            <b-modal
          id="modal-person"
          size="xl"
          title="Extra Large Modal"
          ok-only
          centered 
          ok-title="Cancel"
          hide-header>
          <div v-if="singleperson" class="single-person" >
          
          <h4 class="sub-header">{{ singleperson.fullName }}</h4>
          <br>
          <br>
          <b-row class="center-element">
          <b-col lg="3">
          
          <img :src="singleperson.image" class="img-fluid" alt="" width="100%" style="object-fit: cover;
    width: 100%;
    height: 300px;"/>
          </b-col>
          <b-col lg="9">
          <span   v-html="singleperson.details"></span>
          </b-col>
          
          </b-row>
          </div>
          </b-modal>
          </div> -->
        </div>
      </section>
      <!-- End Team Section -->

      <section id="services" class="services">
        
        <div class="container"><header class="section-header ">
          <p class="sub-header">Partners</p>
        </header>
          <div class="row center-element" >
            <div class="row">
              <img v-for="(partner,index) in Partners" :key="index"
                :src="partner.imageUrl"
                style="padding:0;    height: -webkit-fill-available;"
                class="img-fluid col-sm-2 col-6"
                alt=""
                width="100%"
              />
            </div>

           
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { getDatas, getFields } from "../assets/js/service";
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

import Loading from "./container/Loading.vue";
export default {
    metaInfo: {
    // Children can override the title.
    title: 'About Us',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult About Us"},
      {property:"og:title" ,content:"First Consult About Us"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/about_us"},


      {name:"twitter:title" ,content:"First Consult About Us"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/about_us"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  },
  components: {
    Loading,
       VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
  },
  data() {
    return {
      areaOfExperties: null,
      activePeople:null,
      People: null,
      singleperson:null,
      CurrentAreaOf:null,
      loading:false,
      Partners: null,
      wide : null
    };
  },
  mounted() {
    this.$store.commit("set", ["loading", true]);
    let token = false;
    getFields("areaOfExperties", token).then((resp) => {
      this.areaOfExperties = resp.data;
      this.areaOfExperties.sort((a, b) => a.order > b.order ? 1 : -1);
    });
    getFields("People", token).then((resp) => {
      this.People = resp.data;
     this.People.sort((a, b) => a.order > b.order ? 1 : -1);
    });
    getFields("Partners", token).then((resp) => {
      this.Partners = resp.data;
      this.$store.commit("set", ["loading", false]);
    });

  },
  watch: {
    "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
  methods: {
    activeAccordion(areaOF){
      if(this.wide == areaOF.id){
        this.wide = null;
      }else{
      this.wide = areaOF.id;}
      
    },
    changeActive(person){
      if(this.activePeople == person){this.activePeople = null} else {this.activePeople = person}
    }
  },
};
</script>
<style >

.cart-to-hover:hover #images-in{
  
  opacity: 1;
}
.vsa-list{
    --vsa-max-width: auto;
    --vsa-min-width: auto;
    --vsa-text-color: inherit;
    --vsa-highlight-color: inherit;
    --vsa-bg-color: inherit;
    --vsa-border-color: inherit;
    --vsa-border-width: 0;
    --vsa-border-style: solid;
    --vsa-heading-padding: 0;
    --vsa-content-padding: 0;
    --vsa-default-icon-size: inherit;

}
.vsa-item__trigger__content{
  width: 100%;
}
.vsa-item__heading{
  max-height: 70px;
}
.vsa-item__trigger__icon{
  width: 0;
}
.hidden{
      display: none;
      overflow: hidden;
}

</style>